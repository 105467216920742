<template>
  <div class="detail">
    <div class="theme">
      {{ title }}
    </div>
    <div class="body">
      <div class="list" v-for="(item, index) in list" :key="index">
        <div class="title">{{ index + 1 }}. {{ item }}</div>
        <div class="radios-con">
          <van-radio-group
            v-model="answerList[index].options"
            direction="horizontal"
          >
            <van-radio
              :name="pindex"
              v-for="(pitem, pindex) in options"
              :key="pindex"
            >
              {{ pitem }}
              <template #icon="props">
                <img
                  class="img-icon"
                  :src="props.checked ? icon.active : icon.normal"
                />
              </template>
            </van-radio>
          </van-radio-group>
          <div class="text-area" v-if="answerList[index].options == 2">
            <van-field
              maxlength="200"
              show-word-limit
              v-model="answerList[index].content"
              autosize
              rows="4"
              type="textarea"
              placeholder="请输入内容...（限200字）"
              contenteditable="true"
            />
          </div>
        </div>
      </div>
    </div>
    <van-button class="btn" @click="submit">提交</van-button>
  </div>
</template>

<script>
import { Toast } from "vant";
import LoadList from "@/components/SearchLoadList";
import { getQuestionDetail, subQuestion } from "../api";
export default {
  components: {
    LoadList,
  },
  data() {
    return {
      radio: 0,
      options: ["满意", "基本满意", "不满意"],
      detail: {},
      title: "",
      answerList: [],
      list: [],
      icon: {
        normal: require("@/assets/images/home/noSelect.png"),
        active: require("@/assets/images/home/select.png"),
      },
    };
  },
  created() {
    let id = this.$route.params.id;
    this.init(id);
  },
  methods: {
    init(id) {
      let params = {
        id: id,
      };
      getQuestionDetail(params).then((res) => {
        console.log("radio", res);
        this.title = res.data.title;
        this.list = res.data.items.map((e) => {
          return e.question;
        });
        this.answerList = res.data.items.map((e) => {
          return {
            id: e.id,
            content: "",
          };
        });
      });
    },
    submit() {
      console.log("answerList", this.answerList);
      if (
        this.answerList[0].options >= 0 &&
        this.answerList[1].options >= 0 &&
        this.answerList[2].options >= 0
      ) {
        let params = {
          id: this.$route.params.id,
          content: this.answerList,
          token: window.localStorage.getItem("token"),
        };
        Toast.loading("提交中");
        subQuestion(params).then((res) => {
          if (res.code == 0) {
            Toast.success("提交成功");
            setTimeout(() => {
              this.$router.replace("/questionnaireList");
            }, 1200);
          }
        });
      } else {
        Toast.fail("请选择完整");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.detail {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  .theme {
    width: 100%;
    padding: 0.4rem .3rem 0;
    color: #333333;
    font-size: 0.34rem;
    font-weight: 700;
    text-align: center;
    line-height: 0.6rem;
  }
  .body {
    margin-top: 0.2rem;
    width: 100%;
    padding: 0 0.26rem;
    .list {
      width: 100%;
      padding: 0.2rem 0.1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .title {
        color: #3b93ff;
        font-size: 0.32rem;
      }
      .radios-con {
        font-size: 0.28rem;
        /deep/ .van-radio-group {
          margin-top: 0.2rem;
        }
        .text-area {
          width: 100%;  
          margin-top: 0.2rem;
          border-radius: 0.2rem;
          background-color: #f6faff;
          border: 1px solid rgba(153, 153, 153, 0.2);
        }
      }
    }
  }
  .btn {
    color: #fff;
    background: #3b93ff;
    width: 6.55rem;
    display: block;
    border-radius: 0.2rem;
    line-height: 0.88rem;
    margin: 1rem auto;
    font-size: 0.38rem;
    letter-spacing: 0.1rem;
  }
}
</style>